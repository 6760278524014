





























import { Component, Vue } from "vue-property-decorator";
import FormCard from "@/components/FormCard.vue";
import MailConfiguration from "@/views/Configuration/MailConfiguration.vue";
import ConfigurationsTexts from "@/views/Configuration/ConfigurationsTexts.vue";
import LicenseSlotsConfiguration from "@/views/Configuration/LicenseSlotsConfiguration.vue";
import SignatureConfiguration from "@/views/Configuration/SignatureConfiguration.vue";
import FscCard from "@/components/Card/FscCard.vue";
import CheckListConfiguration from "@/views/Configuration/CheckListConfiguration.vue";
import Switches from "@/views/Configuration/Switches.vue";
import TestingOrganization from "@/views/Configuration/TestingOrganization/TestingOrganization.vue";
import DashboardWarnings from "@/views/Configuration/DashboardWarnings.vue";

@Component({
  components: {
    FscCard,
    LicenseSlotsConfiguration,
    ConfigurationsTexts,
    MailConfiguration,
    FormCard,
    SignatureConfiguration,
    CheckListConfiguration,
    Switches,
    TestingOrganization,
    DashboardWarnings,
  },
})
export default class Configurations extends Vue {
  public name = "Configurations";
}
